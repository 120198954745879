<template>
  <div class="relative">
    <swiper :breakpoints="{
        320: {
            slidesPerView: 2.6,
            slidesPerGroup: 2
        },
        480: {
            slidesPerView: 2.6,
            slidesPerGroup: 2
        },
        640: {
            slidesPerView: 3.6,
            slidesPerGroup: 3
        },
        769: {
            slidesPerView: 4.6,
            slidesPerGroup: 4
        },
        992: {
            slidesPerView: 5.6,
            slidesPerGroup: 5
        },
        1024: {
            slidesPerView: 7,
            slidesPerGroup: 6
        }
    }" :modules="[SwiperNavigation]" :navigation="{ nextEl: nextRef, prevEl: prevRef}" :space-between="16" class="mb-3"
            @swiper="onSwiper">
      <swiper-slide v-for="(product, index) in products" :key="`product-${index}`"
                    :style="getInitialStyle" class="bg-white rounded-lg">
        <product :data="data" :product="product"
                 :source-data="setEventsData(product)"/>
      </swiper-slide>
    </swiper>
    <div ref="prevRef"
         class="flex-center !top-0 !h-full !z-2 swiper-button-prev !w-[32px] flip-icon ltr:-left-4 rtl:left-auto rtl:-right-4"
         @click="onCarousalClick('left-carousal_click')">
      <niceone-image :src="$publicPath('/images/product-nav-left.svg')" width="32"/>
    </div>
    <div ref="nextRef"
         class="flex-center !top-0 !h-full !z-2 swiper-button-next !w-[32px] flip-icon ltr:-right-4 rtl:right-auto rtl:-left-4"
         @click="onCarousalClick('right-carousal_click')">
      <niceone-image :src="$publicPath('/images/product-nav-right.svg')" width="32"/>
    </div>
  </div>
</template>

<script setup>
import Product from "@/components/Product";
import {track} from "~/composables/useTrackEvent";
import {TRACKERS} from "~/constants/events";

const props = defineProps({
  products: {
    type: Array,
    default: () => []
  },
  data: {
    type: Object,
    default: {}
  },
  sourceData: {
    type: Object,
    default: {}
  }
})

const nextRef = ref(null)
const prevRef = ref(null)
const onCarousalClick = (action) => {
  track(TRACKERS.CAROUSAL_CLICK, {
    eventLabel: props.data?.title,
    eventAction: action
  })
}

const setEventsData = (product) => {
  return props?.sourceData.ISIHOME ? props?.sourceData : {
    ...props?.sourceData,
    source_name: product?.en_name,
    source_id: product.id
  }
}

const swiperInit = ref(false)
const onSwiper = () => {
  swiperInit.value = true
}

// Apply style when javascript is disabled
const getInitialStyle = computed(() => {
  if (swiperInit.value) return {}

  return {width: 'calc(100% / 7)'}
})
</script>

